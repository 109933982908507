import React, {useState} from 'react';
import {graphql, Link} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../components/layoutV2"
import SEO from "../components/seo"
import LayoutInner from "../components/layoutInnerV2";
import * as PATHS from "../utils/paths";
import "../utils/index"
import {Button, Grid, Hidden} from "@material-ui/core";
import {WORKSHOPS_EQUITABLE_EPIDEMIOLOGY} from "../utils/paths";
import {
    GridColMainContent,
    GridColQuarterContent,
    GridContainer,
    GridPageHero,
    SectionContainer
} from "../components/GridStuff";

const styles = theme => ({
    root: {},
    nhstug: {
      "& a": {
          color: "#0E0E0E",
          // textDecoration: "none",
      }
    },
    title: {
        fontSize: 86,
        letterSpacing: -1.76,
        lineHeight: 1.08,
        marginTop: 0,
        marginBottom: 12,
        hyphens: "manual",

        [theme.breakpoints.down('sm')]: {
            fontSize: 56,
        },
    },
    subtitle: {
        fontSize: 40,
        letterSpacing: -1.28,
        lineHeight: 1.08,
        fontWeight: 400,
        marginTop: 0,
        marginBottom: 24,
        hyphens: "manual",
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
        },
    },
    heroText: {
        fontFamily: "itext",
        fontSize: 20,
        lineHeight: 1.48,
        marginBottom: 24,
        hyphens: "manual",
    },
    smallText: {
        fontFamily: "itext",
        fontSize: 18,
        lineHeight: 1.24,
        marginBottom: 12,
        hyphens: "manual",
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: 16,
        // },
    },
    referenceText: {
        fontFamily: "itext",
        fontSize: 16,
        lineHeight: 1.24,
        marginBottom: 12,
        hyphens: "manual",
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: 16,
        // },
    },
    inlineLabel: {
        fontFamily: "anext",
        fontWeight: 400,
        letterSpacing: -.5,
    },
    header1: {
        borderTop: "1px solid #000",
        paddingTop: 12,
        fontSize: 44,
        lineHeight: 1.4,
        letterSpacing: -.88,
        marginBottom: 48,
        color: "#0E0E0E",
        // maxWidth: "50%",
        display: "inline-block",
    },
    header2: {
        fontSize: 28,
        lineHeight: 1.2,
        letterSpacing: -0.56,
        marginBottom: 12,
        color: "#0E0E0E",
    },
    header3: {
        fontSize: 24,
        lineHeight: 1.2,
        letterSpacing: -0.48,
        marginBottom: 12,
        color: "#0E0E0E",
    },
    header4: {
        fontFamily: "anext",
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 1.48,
        marginBottom: 0,
        hyphens: "manual",
    },

    headImgWrap: {
        // maxWidth: "100%",
        position: "relative",
        // backgroundImage: "url(https://3iap.com/cdn/images/3iap-workshop-equitable-epidemiology-head.png)",
        // height: "100%",
        // backgroundSize: "120%",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        // flex: 1,
        // backgroundColor: "blue",
        // minHeight: "100%",
        aspectRatio: 591/444,
        [theme.breakpoints.down('xs')]: {
            // height: 180,
            marginBottom: 48,
        },
        // height: 240,

    },


    headImg: {

        position: "absolute",
        // left: "-4%",
        // right: "-13.8%",
        left: "-2%",
        right: "0%",
        top: "0%",
        // bottom: "-13%",
        // maxHeight: "120%",

        maxWidth: "100%",
        [theme.breakpoints.down('xs')]: {
            // maxHeight: 360,
            // height: 240,
        },

    },

    midImgWrap: {
        // maxWidth: "100%",
        position: "relative",
        // backgroundImage: "url(https://3iap.com/cdn/images/3iap-workshop-equitable-epidemiology-head.png)",
        // height: "100%",
        // backgroundSize: "120%",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        // flex: 1,
        // backgroundColor: "blue",
        // minHeight: "100%",
        aspectRatio: 617/452,
        [theme.breakpoints.down('xs')]: {
            // height: 180,
            marginBottom: 48,
        },
        // height: 240,

    },
    midImg: {

        position: "absolute",
        // left: "-4%",
        // right: "-13.8%",
        left: "-2%",
        right: "0%",
        top: "0%",
        // bottom: "-13%",
        // maxHeight: "120%",

        maxWidth: "100%",
        [theme.breakpoints.down('xs')]: {
            // maxHeight: 360,
            // height: 240,
        },

    },

    spacer: {
      height: 96,
    },
    referenceList: {
        // paddingLeft: "1em",
        paddingLeft: 0,
        listStyleType: "none",
        lineHeight: 1,
        "& li": {
            // marginBottom: 12,
            fontFamily: "itext",
            fontSize: 18,
            lineHeight: 1.24,
            marginBottom: 12,
            hyphens: "manual",

            // fontSize: 18,
            // lineHeight: 1.333,
            // marginBottom: 24,
            // [theme.breakpoints.down('sm')]: {
            //     fontSize: 14,
            // },
        },
        "& b": {
            fontWeight: 600,
        }
    },
    videoWrapper: {
        position: "relative",
        paddingBottom: "56.25%", /* 16:9 */
        paddingTop: 25,
        height: 0,
    },
    video: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    }
});


const ReferenceItem = withStyles(styles)(({overlineLabel, url, linkText, blurb, children, classes}) => {
    return (
        <div style={{marginBottom: 24}}>
            {/*{overlineLabel && <h4 className={classes.header4}>{overlineLabel}</h4>}*/}
            <p className={classes.smallText}>
                {overlineLabel && <span className={classes.inlineLabel}>{overlineLabel}<br/></span>}

                <a href={url} dangerouslySetInnerHTML={{__html: linkText}}/> {blurb} {children}


            </p>
        </div>
    )
})



const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <Layout location={location} title={siteTitle} hideNewsletterForm={false} className={classes.nhstug}>
            <SEO title={"San Francisco CPU Workshop Materials"} location={{pathname: "/sfcpu2024"}} imagePath={"/images/3iap-mbat-head-hor-v02.png"}/>

            <LayoutInner>

                <GridPageHero
                    imgURLHorizontal={"https://3iap.com/cdn/images/3iap-eqep-head-hor-v03.png"}
                    imgURLVertical={"https://3iap.com/cdn/images/3iap-eqep-head-vert-v03.png"}
                >
                    <h1 className={classes.title}>Equitable Dataviz Workshop Materials</h1>
                    <h2 className={classes.subtitle}>Follow up materials for the San Francisco's City Performance Unit.</h2>

                    <p className={classes.heroText}>
                        Thank you for the opportunity to share “Equitable Dataviz: AHow to visualize social issues, without making them worse..” Follow up materials are below for your convenience.
                    </p>


                    <Link to={PATHS.CONTACT_PAGE} style={{textDecoration: "none"}}>
                        <Button variant={"contained"} color={"primary"}>Get In Touch</Button>
                    </Link>
                </GridPageHero>


                <div className={classes.spacer}/>



                <SectionContainer title={"Related Research"}>
                    <GridContainer style={{rowGap: 48}}>
                        <GridColQuarterContent>
                            <div className={classes.videoWrapper}>
                                <iframe className={classes.video}
                                        src="https://www.youtube.com/embed/jp9_ccNn3ZA?si=UfS-BRyWy5zWxAeO"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <br/><br/>

                            <h3>Must Be A Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented
                                Visualizations of Population Health Disparities</h3>
                            <p className={classes.smallText}>
                                Collaboration with Lace Padilla, testing equitable data design techniques for population
                                mortality charts.
                            </p>
                            <br/>

                            <ReferenceItem
                                overlineLabel={"Research Paper"}
                                url={"https://doi.org/10.31219/osf.io/nrgt8"}
                                linkText={"Must Be A Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented Visualizations of Population Health Disparities"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Study Supplement"}
                                url={"https://osf.io/emb8y"}
                                linkText={"MBAT Data, Analysis, Supplemental Material"}
                                // blurb={"XXX"}
                            />


                            <ReferenceItem
                                overlineLabel={"VIS Talk"}
                                url={"https://www.youtube.com/watch?v=jp9_ccNn3ZA"}
                                linkText={"Must Be A Tuesday"}
                                blurb={"Presentation for IEEE VIS 2024"}
                            />
                        </GridColQuarterContent>


                        <GridColQuarterContent>
                            <div className={classes.videoWrapper}>
                                <iframe className={classes.video}
                                        src="https://www.youtube.com/embed/AyUkX_m94k0?si=sdx76Qk2tAOrZJly"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <br/><br/>

                            <h3>Dispersion vs Disparity: Hiding Variability Can Encourage
                                Stereotyping When Visualizing Social Outcomes</h3>
                            <p className={classes.smallText}>Collaboration with Cindy Xiong Bearfield, exploring the
                                impact
                                of variability on attribution and
                                stereotypes.</p>
                            <br/>

                            <ReferenceItem
                                overlineLabel={"Research Paper"}
                                url={"https://arxiv.org/abs/2208.04440"}
                                linkText={"Dispersion vs Disparity: Hiding Variability Can Encourage Stereo&shy;typing When Visualizing Social Outcomes"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Nightingale"}
                                url={"https://3iap.com/unfair-comparisons-how-visualizing-social-inequality-can-make-it-worse-ZTmaoCrsSeanEW00O2jnsQ/"}
                                linkText={"Unfair Comparisons: How Visualizing Social Inequality Can Make It Worse"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Study Details"}
                                url={"https://3iap.com/dispersion-disparity-equity-centered-data-visualization-research-project-Wi-58RCVQNSz6ypjoIoqOQ/"}
                                linkText={"Dispersion vs Disparity Research Project"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Motivation"}
                                url={"https://3iap.com/what-can-go-wrong-racial-equity-data-visualization-deficit-thinking-VV8acXLQQnWvvg4NLP9LTA/"}
                                linkText={"What Can Go Wrong? Deficit Thinking in Dataviz"}
                                blurb={"A collaboration with Pieta Blakely"}
                            />

                            <ReferenceItem
                                overlineLabel={"VIS Talk"}
                                url={"https://www.youtube.com/watch?v=AyUkX_m94k0"}
                                linkText={"Dispersion vs Disparity"}
                                blurb={"Presentation for IEEE VIS 2022"}
                            />
                        </GridColQuarterContent>

                        <GridColQuarterContent>
                            <div className={classes.videoWrapper}>
                                <iframe className={classes.video}
                                        src="https://www.youtube.com/embed/Lbzr4AQ66Cs?si=d0L3AUuprlCGusIu"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <br/><br/>




                            <h3>Polarizing Political Polls: How Visual
                                <wbr/>ization Design Choices Can Shape Public Opinion and Increase Political
                                Polarization
                            </h3>

                            <p className={classes.smallText}>Collaboration with Cindy Xiong Bearfield, exploring how
                                social normative influences are triggered by charts.</p>

                            <br/>

                            <ReferenceItem
                                overlineLabel={"Research Paper"}
                                url={"https://arxiv.org/abs/2309.00690"}
                                linkText={"Polarizing Political Polls: How Visualization Design Choices Can Shape Public Opinion and Increase Political Polarization"}
                            />

                            <ReferenceItem
                                overlineLabel={"Nightingale"}
                                url={"https://nightingaledvs.com/divisive-dataviz-how-political-data-journalism-divides-our-democracy/"}
                                linkText={"Divisive Dataviz: How Political Data Journalism Divides Our Democracy"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Study Details"}
                                url={"https://3iap.com/polarizing-political-polls-dataviz-research-project-WPct6Y52Q6WwSEqJk0Mmeg/"}
                                linkText={"Polarizing Political Polls Research Project"}
                                // blurb={"XXX"}
                            />
                            <ReferenceItem
                                overlineLabel={"Background"}
                                url={"https://3iap.com/political-psychology-primer-for-information-designers-Ol4B1UakTqi42tjIS4l_Vw/"}
                                linkText={"Through a Partisan Lens: How Prior Political Beliefs Override Information."}
                                // blurb={"XXX"}
                            />


                            <ReferenceItem
                                overlineLabel={"VIS Talk"}
                                url={"https://www.youtube.com/watch?v=Lbzr4AQ66Cs"}
                                linkText={"Polarizing Political Polls IEEE VIS 2023 Talk"}
                                // blurb={"XXX"}
                            />

                        </GridColQuarterContent>




                        {/*<Hidden smDown>*/}
                        {/*    <GridColQuarterContent>*/}

                        {/*            <div className={classes.midImgWrap}>*/}
                        {/*                /!*<div className={classes.headImgWrapInner}>*!/*/}

                        {/*                <img className={classes.midImg}*/}
                        {/*                     src={"https://3iap.com/cdn/images/mbat-geo-emph-mid.png"}/>*/}
                        {/*                /!*</div>*!/*/}
                        {/*            </div>*/}

                        {/*    </GridColQuarterContent>*/}
                        {/*</Hidden>*/}

                    </GridContainer>




                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer title={"Related Material"}>
                    <GridContainer>
                        <GridColQuarterContent>
                            <h3>Equitable Epidemiology</h3>

                            <img style={{width: "100%"}}
                                 src={"https://3iap.com/cdn/images/3iap-eqep-head-hor-v03.png"}/>

                            <br/><br/>


                            <ReferenceItem
                                overlineLabel={"3iap Workshop"}
                                url={"/workshops/equitable-epidemiology-population-health-dataviz-training/"}
                                linkText={"Equitable Epidemiology: Deep Dive Workshops for Public Health Communicators"}
                                blurb={"In these deep-dive workshops, we’ll seek to motivate an elevated duty-of-care for public health data communication, cover techniques for identifying prevalent risks in messaging, and propose alternative, evidence-backed chart choices for common use cases for visualizing population health outcomes."}
                            />
                        </GridColQuarterContent>

                        {/*<GridColQuarterContent>*/}
                        {/*    <h3>Deathly Dataviz</h3>*/}

                        {/*    <div className={classes.videoWrapper}>*/}
                        {/*        <iframe className={classes.video}*/}
                        {/*                src="https://www.youtube.com/embed/llHkUHGwc18?t=3641s"*/}
                        {/*                title="YouTube video player" frameBorder="0"*/}
                        {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
                        {/*                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>*/}
                        {/*    </div>*/}
                        {/*    <br/>*/}


                        {/*    <ReferenceItem*/}
                        {/*        overlineLabel={"NHS TUG Talk"}*/}
                        {/*        url={"https://www.youtube.com/watch?v=llHkUHGwc18&t=3641s"}*/}
                        {/*        linkText={"Deathly Dataviz: A morbid introduction to Equitable Epidemiology."}*/}
                        {/*        blurb={"Presentation for UK's NHS TUG on how popular population health charts can backfire and undermine public health. And what we can do instead."}*/}
                        {/*    />*/}
                        {/*</GridColQuarterContent>*/}

                        <GridColQuarterContent>
                            <h3>Visual Villainy</h3>

                            <div className={classes.videoWrapper}>
                                <iframe className={classes.video}
                                        src="https://www.youtube.com/embed/rlNy3Z40Lp0"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <br/>


                            <ReferenceItem
                                overlineLabel={"Outlier"}
                                url={"https://www.youtube.com/watch?v=rlNy3Z40Lp0"}
                                linkText={"Visual Villainy: How to Undermine Humanity with Dataviz"}
                                blurb={"Outlier keynote on design considerations for ethical data visualization."}
                            />
                        </GridColQuarterContent>

                        <GridColQuarterContent>
                            <h3>Miscellaneous Related Writing</h3>

                            <ReferenceItem
                                // overlineLabel={"XXX"}
                                url={"https://www.effaff.com/sketchy-bar-charts/"}
                                linkText={"Sketching Sketchy Bar Charts"}
                                blurb={"Understanding how viewers underestimate variability when viewing bar charts of averages."}
                            />

                            <ReferenceItem
                                // overlineLabel={"XXX"}
                                url={"https://www.effaff.com/health-risk-data-contrast-effects/"}
                                linkText={"When is eleven scarier than twelve?"}
                                blurb={"Understanding perceptions of relative health risk."}
                            />

                            <ReferenceItem
                                // overlineLabel={"XXX"}
                                url={"https://www.effaff.com/read-the-room-ensemble-effect/"}
                                linkText={"Read the room."}
                                blurb={"The 'ensemble effect' helps explain why Jitter Plots feel so intuitive."}
                            />


                        </GridColQuarterContent>

                        <GridColQuarterContent>
                            <h3>Chart Chat</h3>
                            <div className={classes.videoWrapper}>
                                <iframe className={classes.video}
                                        src="https://www.youtube.com/embed/kjHhRx8hmAI"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <br/>

                            <ReferenceItem
                                // overlineLabel={"XXX"}
                                url={"https://www.youtube.com/watch?v=kjHhRx8hmAI"}
                                linkText={"Chart Chat 53 with Eli Holder"}
                                blurb={"Outlier keynote on design considerations for ethical data visualization."}
                            >
                                Chart Chat hosts Steve Wexler, Jeff Shaffer, Amanda Makulec, and Andy Cotgreave are
                                joined by Eli Holder, principal at 3 is a Pattern and researcher who has dug into some
                                sticky topics around how we represent (or misrepresent) information through
                                visualization.
                            </ReferenceItem>
                        </GridColQuarterContent>
                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer title={"Talk Citations"}>
                    <GridContainer>

                        <GridColQuarterContent>
                            <h3>Introduction</h3>


                            <ReferenceItem
                                overlineLabel={"Rogers 2023"}
                                url={"https://www.theguardian.com/news/datablog/2013/mar/15/john-snow-cholera-map"}
                                linkText={"John Snow's data journalism: the cholera map that changed the world"}
                            />

                            <ReferenceItem
                                overlineLabel={"Milkman et al. 2021"}
                                url={"https://doi.org/10.1038/s41586-021-04128-4"}
                                linkText={"Megastudies improve the impact of applied behavioural science"}
                            />

                            <ReferenceItem
                                overlineLabel={"Blakely 2019"}
                                url={"https://rojasblakely.com/presenting-data-for-a-targeted-universalist-approach/"}
                                linkText={"Presenting data for a Targeted Universalist approach"}
                                // blurb={"XXX"}
                            />



                        </GridColQuarterContent>



                        <GridColQuarterContent>
                            <h3>The Data Stories We Tell Ourselves.</h3>


                            <ReferenceItem
                                overlineLabel={"Rogers 2023"}
                                url={"https://www.theguardian.com/news/datablog/2013/mar/15/john-snow-cholera-map"}
                                linkText={"John Snow's data journalism: the cholera map that changed the world"}
                            />

                            <ReferenceItem
                                overlineLabel={"Milkman et al. 2021"}
                                url={"https://doi.org/10.1038/s41586-021-04128-4"}
                                linkText={"Megastudies improve the impact of applied behavioural science"}
                            />

                            <ReferenceItem
                                overlineLabel={"Heider & Simmel 1944"}
                                url={"https://doi.org/10.2307/1416950"}
                                linkText={"An experimental study of apparent behavior."}

                            />

                            <ReferenceItem
                                overlineLabel={"Xiong et al 2019"}
                                url={"https://arxiv.org/abs/1908.00215"}
                                linkText={"Illusion of Causality in Visualized Data"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"NYC DPH"}
                                url={"https://a816-dohbesp.nyc.gov/IndicatorPublic/data-explorer/asthma/"}
                                linkText={"New York City Department of Health, Environment & Health Data Portal."}
                                // blurb={"XXX"}
                            />




                        </GridColQuarterContent>



                        <GridColQuarterContent>
                            <h3>Pointing The Finger.</h3>

                            <ReferenceItem
                                overlineLabel={"Jhangiani & Tarry 2022"}
                                url={"https://opentextbc.ca/socialpsychology/chapter/biases-in-attribution/"}
                                linkText={"Biases in Attribution"}
                                // blurb={"XXX"}
                            />
                            <ReferenceItem
                                overlineLabel={"Davis & Museus 2019"}
                                url={"http://dx.doi.org/10.3998/currents.17387731.0001.110"}
                                linkText={"What Is Deficit Thinking? An Analysis of Conceptualizations of Deficit Thinking and Implications for Scholarly Research"}
                                // blurb={"XXX"}
                            />
                            <ReferenceItem
                                overlineLabel={"Holder & Padilla 2024"}
                                url={"https://osf.io/c28wg"}
                                linkText={"Must Be a Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented Visualizations of Population Health Disparities"}
                                // blurb={"XXX"}
                            />


                            <ReferenceItem
                                overlineLabel={"DP Council 2023"}
                                url={"https://csph.d8.theopenscholar.com/files/systemsforaction/files/sdoh-wh-playbook.pdf"}
                                linkText={"The U.S. Playbook to Address Social Determinants of Health"}
                                // blurb={"XXX"}
                            />

                            {/* Davis & Museus 2019 “What Is Deficit Thinking? An Analysis of Conceptualizations of Deficit Thinking and Implications for Scholarly Research”*/}
                            <ReferenceItem
                                overlineLabel={"Mounk 2014"}
                                url={"https://dash.harvard.edu/handle/1/14226053"}
                                linkText={"The Age of Responsibility- On the Role of Choice, Luck and Personal Responsibility in Contemporary Politics and Philosophy"}
                                // blurb={"XXX"}
                            />
                            <ReferenceItem
                                overlineLabel={"Hook & Markus 2020"}
                                url={"https://doi.org/10.1177/1745691619896252"}
                                linkText={"Health in the United States: Are Appeals to Choice and Personal Responsibility Making Americans Sick?"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Braveman & Dominguez 2021"}
                                url={"https://doi.org/10.3389/fpubh.2021.689462"}
                                linkText={"Abandon 'Race.' Focus on Racism."}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Gollust & Lynch 2011"}
                                url={"https://doi.org/10.1215/03616878-1460578"}
                                linkText={"Who Deserves Health Care? The Effects of Causal Attributions and Group Cues on Public Attitudes About Responsibility for Health Care Costs"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Hoffman et al 2016"}
                                url={"https://doi.org/10.1073%2Fpnas.1516047113"}
                                linkText={"Racial bias in pain assessment and treatment recommend-ations, and false beliefs about biological differences between blacks and whites"}
                                // blurb={"XXX"}
                            />


                            <ReferenceItem
                                overlineLabel={"Horne et al 2019"}
                                url={"https://doi.org/10.1016/j.tics.2018.12.004"}
                                linkText={"Explanation as a Cognitive Process"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Matute et al 2015"}
                                url={"https://doi.org/10.3389/fpsyg.2015.00888"}
                                linkText={"Illusions of causality: how they bias our everyday thinking and how they could be reduced"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Vadillo et al. 2013"}
                                url={"https://doi.org/10.11144/Javeriana.upsy12-1.ficu"}
                                linkText={"Fighting the Illusion of Control: How to Make Use of Cue Competition and Alternative Explanations"}
                                // blurb={"XXX"}
                            />
                            <ReferenceItem
                                overlineLabel={"D'Ignazio & Klein 2020"}
                                url={"https://data-feminism.mitpress.mit.edu/"}
                                linkText={"Data Feminism"}
                                // blurb={"XXX"}
                            />





                        </GridColQuarterContent>


                        <GridColQuarterContent>
                            <h3>Divisive Dataviz.</h3>



                            <ReferenceItem
                                overlineLabel={"Jhangiani & Tarry 2022"}
                                url={"https://opentextbc.ca/socialpsychology/chapter/social-categorization-and-stereotyping/"}
                                linkText={"Social Categorization and Stereotyping"}
                            />



                            <ReferenceItem
                                overlineLabel={"Wilmer & Kerns 2022"}
                                url={"https://osf.io/preprints/osf/av5ey"}
                                linkText={"What’s really wrong with bar graphs of mean values: variable and inaccurate communication of evidence on three key dimensions"}
                                // blurb={"XXX"}
                            />


                            <ReferenceItem
                                overlineLabel={"Holder & Xiong 2022"}
                                url={"https://arxiv.org/abs/2208.04440"}
                                linkText={"Dispersion vs Disparity: Hiding Variability Can Encourage Stereotyping When Visualizing Social Outcomes"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Holder & Padilla 2024"}
                                url={"https://osf.io/c28wg"}
                                linkText={"Must Be a Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented Visualizations of Population Health Disparities"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Cimpian & Salomon 2014:"}
                                url={"https://doi.org/10.1017/s0140525x13002197"}
                                linkText={"The inherence heuristic: An intuitive means of making sense of the world, and a potential precursor to psychological essentialism"}
                            />

                            <ReferenceItem
                                overlineLabel={"Kite & Whitley 2016"}
                                url={"http://dx.doi.org/10.4324/9780367809218-5"}
                                linkText={"Old-fashioned and contemporary forms of prejudice"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Leitner et al 2016"}
                                url={"https://doi.org/10.1016/j.socscimed.2016.10.007"}
                                linkText={"Racial bias is associated with ingroup death rate for Blacks and Whites: Insights from Project Implicit"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Orchard et al 2017"}
                                url={"https://doi.org/10.1016/j.socscimed.2017.03.036"}
                                linkText={"County-level racial prejudice and the black-white gap in infant health outcomes"}
                                // blurb={"XXX"}
                            />


                            <ReferenceItem
                                overlineLabel={"Brown-Iannuzzi et al 2016"}
                                url={"https://doi.org/10.1177/0956797616674999"}
                                linkText={"The Relationship Between Mental Representations of Welfare Recipients and Attitudes Toward Welfare"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Snowden & Graaf 2019"}
                                url={"https://doi.org/10.1177/0095798419844129"}
                                linkText={"The 'Undeserving Poor,' Racial Bias, and Medicaid Coverage of African Americans"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Gollust & Lynch 2011"}
                                url={"https://doi.org/10.1215/03616878-1460578"}
                                linkText={"Who Deserves Health Care? The Effects of Causal Attributions and Group Cues on Public Attitudes About Responsibility for Health Care Costs"}
                                // blurb={"XXX"}
                            />

                            <ReferenceItem
                                overlineLabel={"Jhangiani & Tarry 2022"}
                                url={"https://opentextbc.ca/socialpsychology/chapter/other-determinants-of-helping/"}
                                linkText={"Other Determinants of Helping"}
                                // blurb={"XXX"}
                            />





                        </GridColQuarterContent>







                    </GridContainer>
                </SectionContainer>


            </LayoutInner>


        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
